#about-content a {
    color: $theme-color;
    word-break: break-all;

    &:hover {
        border-bottom: $content-link-border;
    }

    &.fancybox {
        border: 0;
    }
}